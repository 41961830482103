import { FormControl, FormLabel, MenuItem, TextField } from "@mui/material"
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";

import customParseFormat from 'dayjs/plugin/customParseFormat';

import styles from "./DateSelector.module.css";

dayjs.extend(customParseFormat)


const days: number[] = [];

for (let i = 0; i < 31; i++) {
  days.push(i + 1);
}

const years: number[] = [];

for (let i = 1940; i < 2024; i++) {
  years.push(i + 1);
}

const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь",]

interface IDateSelectorState {
  day?: number;
  month?: number;
  year?: number;
}

interface DateSelectorProps {
  onChange: (date: Dayjs) => void;
  defaultValue?: Dayjs;
  required?: boolean;
}

export const DateSelector: FC<DateSelectorProps> = ({ onChange, defaultValue, required }) => {
  const [date, setDate] = useState<IDateSelectorState>({});
  const [isDateInvalid, setIsDateInvalid] = useState(false);

  useEffect(() => {
    if (!defaultValue) return;
    const [day, month, year] = defaultValue.format("DD.MM.YYYY").split(".")
    setDate({
      day: parseInt(day, 10),
      month: parseInt(month, 10),
      year: parseInt(year),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { day, month, year } = date;

    if (!day || !month || !year) {
      return;
    }

    const d = dayjs(`${day?.toString().padStart(2, "0")}.${month?.toString().padStart(2, "0")}.${year}`, "DD.MM.YYYY", true);
    if (!d.isValid()) {
      setIsDateInvalid(true);
      return;
    };
    onChange(d)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const _onChange = (field: keyof IDateSelectorState, value: string) => {
    setIsDateInvalid(false);
    setDate(obj => ({ ...obj, [field]: parseInt(value, 10) }))
  }


  return (
    <FormControl>
      <FormLabel className={styles.Label} required={required}>Дата рождения</FormLabel>
      <div className={styles.Container}>
        <TextField
          className={styles.Days}
          required={required}
          label="День"
          select
          value={date.day || ""}
          error={isDateInvalid}
          onChange={e => _onChange("day", e.target.value)}
        >
          {days.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
        </TextField>
        <TextField
          className={styles.Months}
          required={required}
          select
          label="Месяц"
          value={date.month || ""}
          error={isDateInvalid}
          onChange={e => _onChange("month", e.target.value)}
        >
          {months.map((m, i) => <MenuItem key={m} value={i + 1}>{m}</MenuItem>)}
        </TextField>
        <TextField
          className={styles.Years}
          required={required}
          select
          label="Год"
          value={date.year || ""}
          error={isDateInvalid}
          onChange={e => _onChange("year", e.target.value)}
        >
          {years.map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
        </TextField>
      </div>
    </FormControl>
  )
}