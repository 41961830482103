import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const timezones = [
  {
    name: "Europe/Kaliningrad",
    label: "Калининград",
    offset: 2,
  },
  {
    name: "Europe/Moscow",
    label: "Москва",
    offset: 3,
  },
  {
    name: "Europe/Samara",
    label: "Самара",
    offset: 4,
  },
  {
    name: "Asia/Yekaterinburg",
    label: "Екатеринбург",
    offset: 5,
  },
  {
    name: "Asia/Omsk",
    label: "Омск",
    offset: 6,
  },
  {
    name: "Asia/Novosibirsk",
    label: "Новосибирск",
    offset: 7,
  },
  {
    name: "Asia/Irkutsk",
    label: "Иркутск",
    offset: 8,
  },
  {
    name: "Asia/Chita",
    label: "Чита",
    offset: 9,
  },
  {
    name: "Asia/Vladivostok",
    label: "Владивосток",
    offset: 10,
  },
  {
    name: "Asia/Magadan",
    label: "Магадан",
    offset: 11,
  },
  {
    name: "Asia/Kamchatka",
    label: "Петропавловск-Камчатский",
    offset: 12,
  }
] as const;

const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / (6e4 * 60);
}

export const currentTimezone = timezones.find(el => el.offset === Math.floor(getOffset(dayjs.tz.guess())));