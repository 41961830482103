import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { FC } from "react";

const times: Record<string, string>[] = [];

const startHour = 8;
const endHour = 22;
let i = startHour;

while (i <= endHour) {
  const time = `${i.toString().padStart(2, "0")}:00`
  times.push({
    label: time,
    value: time,
  });
  i++;
}

interface TimeSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export const TimeSelector: FC<TimeSelectorProps> = ({ value, onChange }) => {
  return (
    <FormControl fullWidth required>
      <InputLabel id="timeSelector">Выберите удобное для уведомлений время</InputLabel>
      <Select
        labelId="timeSelector"
        label="Выберите удобное для уведомлений время"
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        {times.map(el => (
          <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}