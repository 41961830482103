import { Container, Typography } from "@mui/material";

import styles from "./SuccessFullRegistration.module.css";

export const SuccessFullRegistration = () => (
  <Container className={styles.Container}>
    <Typography variant="h3" align="center">
      Данные успешно сохранены
    </Typography>
    <Typography variant="body1" align="center">
      Вернитесь в телеграм, для дальнейшего использования бота
    </Typography>
  </Container>
)