import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { timezones } from "../../constants/timezones";

interface TimezoneSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export const TimezoneSelector: FC<TimezoneSelectorProps> = ({ value, onChange }) => {
  return (
    <FormControl fullWidth required>
        <InputLabel id="timezoneSelector">Выберите Ваш часовой пояс</InputLabel>
        <Select
          labelId="timezoneSelector"
          label="Выберите Ваш часовой пояс"
          value={value}
          onChange={e => onChange(e.target.value)}
        >
          {timezones.map(el => (
            <MenuItem key={el.name} value={el.name}>{`${el.label} UTC+${el.offset}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}