import { Alert, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import 'dayjs/locale/ru';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { instance } from '../../axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TimezoneSelector } from '../../components/TimezoneSelector';
import { currentTimezone } from '../../constants/timezones';
import { TimeSelector } from '../../components/TimeSelector';
import { DateSelector } from '../../components/DateSelector';

import styles from "./RegistrationForm.module.css";

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)

interface User {
  firstName: string;
  lastName: string;
  middleName?: string;
  birthDay: string;
  sex: string;
  diagnosis: string;
  isRegistrationCompleted: boolean;
  timezone: string;
  notificationTime: string;
}

const defaultNotificationTime = "15:00";

const defaultStore: User = {
  firstName: "",
  lastName: "",
  middleName: "",
  birthDay: "",
  sex: "",
  diagnosis: "",
  isRegistrationCompleted: false,
  timezone: currentTimezone?.name || "",
  notificationTime: defaultNotificationTime
};

const optionFields: (keyof User)[] = ["middleName", "isRegistrationCompleted"]

export const RegistrationForm = () => {
  const [isError, setIsError] = useState(false);
  const [store, setStore] = useState<User>(defaultStore);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getToken = async () => {
      try {
        setIsLoading(true);
        const { data: newToken } = await instance.get<string>(`/session/${searchParams.get("sessionId")}/user/${searchParams.get("id")}`);
        localStorage.setItem("token", newToken);
      } catch (e) { }
      setIsLoading(false);
    }

    const getUser = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("token");
        const { data: user } = await instance.get<User>(`/user/${searchParams.get("id")}`, {
          headers: {
            Authorization: token,
          }
        });
        if (user) {
          setStore({
            firstName: user.firstName,
            lastName: user.lastName,
            middleName: user.middleName,
            birthDay: user.birthDay,
            sex: user.sex,
            diagnosis: user.diagnosis,
            isRegistrationCompleted: user.isRegistrationCompleted,
            timezone: user.timezone || currentTimezone?.name || "",
            notificationTime: user.notificationTime || defaultNotificationTime,
          });
        }
      } catch (e) {
        console.error(e);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    const loadData = async () => {
      await getToken();
      await getUser();
    }

    loadData();
  }, [searchParams]);

  const onSave = async () => {
    try {
      setIsError(false);
      await instance.post("/profile", {
        ...store,
        uuid: searchParams.get("id"),
        isRegistration: !store.isRegistrationCompleted,
      });
      navigate("/success-registration")
      window.open(process.env.REACT_APP_BOT_LINK)
    } catch (e) {
      setIsError(true);
      console.error(e);
    }
    return;
  }
  const onChange = (field: keyof typeof defaultStore, value: string) => {
    setStore(obj => ({
      ...obj,
      [field]: value,
    }))
  }

  const isDisabled = Object.keys(store).some(el => {
    const key = el as typeof optionFields[number];
    return !optionFields.includes(key) && !store[key]
  });

  if (isLoading) return (
    <Container className={styles.LoaderContainer}>
      <CircularProgress />
    </Container>
  )

  return (
    <Container className={styles.MainContainer}>
      <Typography className={styles.Header} variant="h4">
        {store.isRegistrationCompleted ? "Редактирование профиля в NeuroBot" : "Регистрация в NeuroBot"}
      </Typography>
      {isError && <Alert severity="error">Что-то сломалось;( Попробуйте пройти регистрацию позже</Alert>}
      <TextField label="Фамилия" required autoComplete="off" value={store.lastName} onChange={e => onChange("lastName", e.target.value)} />
      <TextField label="Имя" required autoComplete="off" value={store.firstName} onChange={e => onChange("firstName", e.target.value)} />
      <TextField label="Отчество" autoComplete="off" value={store.middleName} onChange={e => onChange("middleName", e.target.value)} />
      <FormControl fullWidth required>
        <InputLabel id="genderSelector">Пол</InputLabel>
        <Select
          labelId="genderSelector"
          label="Пол"
          value={store.sex}
          onChange={e => onChange("sex", e.target.value)}
        >
          <MenuItem value="MALE">Мужской</MenuItem>
          <MenuItem value="FEMALE">Женский</MenuItem>
        </Select>
      </FormControl>
      <DateSelector required defaultValue={dayjs(store.birthDay, "DD.MM.YYYY")} onChange={d => onChange("birthDay", d.format("DD.MM.YYYY"))} />
      <FormControl fullWidth required>
        <InputLabel id="diagnosisSelector">Выставлен ли вам диагноз</InputLabel>
        <Select
          labelId="diagnosis"
          label="Выставлен ли вам диагноз"
          value={store.diagnosis}
          onChange={e => onChange("diagnosis", e.target.value)}
        >
          <MenuItem value="PD">Болезнь Паркинсона</MenuItem>
          <MenuItem value="AD">Болезнь Альцгеймера</MenuItem>
          <MenuItem value="ET">Эссенциальный тремор</MenuItem>
          <MenuItem value="HG">Хорея Гентингтона</MenuItem>
          <MenuItem value="D">Дистония</MenuItem>
          <MenuItem value="CCI">Хроническая ишемия головного мозга</MenuItem>
          <MenuItem value="PD_ET">Болезнь Паркинсона и эссенциальный тремор</MenuItem>
          <MenuItem value="NONE">Здоров(-а)</MenuItem>
        </Select>
      </FormControl>
      <TimezoneSelector value={store.timezone} onChange={value => onChange("timezone", value)} />
      <TimeSelector value={store.notificationTime} onChange={value => onChange("notificationTime", value)} />
      <Button variant="contained" disabled={isDisabled} onClick={onSave}>
        Сохранить
      </Button>
    </Container>
  );
}

